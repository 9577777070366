<template>
  <div v-on:click="beginRevocation">
    <GoodbyeWorld />
  </div>
</template>

<script>
import GoodbyeWorld from './components/GoodbyeWorld.vue'

export default {
  name: 'App',
  components: {
    GoodbyeWorld
  }
}
</script>

<style>

html{
  background: #000;
}

body,html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'IM Fell English SC', serif;
  /* WHAT HAS CREATED THIS */
}

body,html,a,input{
  cursor: url('../public/ocu.png'), auto;
}

div{
  display: block;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #000;
  margin: 0;
  padding: 0;
}
</style>
