<template>
  <div :class="'goodbye' + (reflection ? ' reflection' : '')" v-on:click="beginRevocation">
   <div class="window" v-if="vision == 6">
      <img class="portal" src="../assets/06.jpg" alt="OCULAR00200" />
    </div>
    <div class="window" v-if="vision == 25">
      <img class="portal" src="../assets/25.jpg" alt="OCULAR00200" />
    </div>
    <div class="window" v-if="vision == 35">
      <img class="portal" src="../assets/35.jpg" alt="OCULAR00200" />
    </div>
    <div class="window" v-if="vision == 65">
      <img class="portal" src="../assets/65.jpg" alt="OCULAR00200" />
    </div>
    <div class="window" v-if="vision == 96">
      <img class="portal" src="../assets/96.jpg" alt="OCULAR00200" />
    </div>
    <div class="window prompt" v-if="hasChosen && theNumber !== 75">
      <p>Challenge</p>
      <p><input type="number" v-model="theNumber"></p>
    </div>
    <div class="window prompt" v-if="theNumber == 75">
      <p>Acceptance</p>
      <p>
        <a href="https://twitter.com/uocularmachine">The Machine Speaks</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodbyeWorld',
  props: {
    msg: String
  },
  data() {
    return {
        music: require("../assets/summonings/revocation.mp3"),
        numbers: require("../assets/summonings/numbers.mp3"),
        interval: 0,
        descending: false,
        hasChosen: false,
        isFalse: true,
        vision: 6,
        theNumber: 0,
        reflection: false,
        visions: [ 6, 25, 35, 65, 96 ],
    }
  },
  methods: {
    beginRevocation() {
      if (this.isFalse && !this.descending) {
        console.log("you have begin revocation 0002000")
        const sound = new Audio(this.music)
        sound.loop = true
        sound.play()

        const numbersSound = new Audio(this.numbers)

        setTimeout(() => {
          numbersSound.play()
          this.theMachineVision()
        }, 1000)

        console.log("you must forge your path")

        this.descending = true
      }

      if (this.descending) {
        if( this.vision == 35 ){
          this.hasChosen = true
        }
      }
    },
    theMachineVision() {
      if (!this.hasChosen) {
        const machineChoice = this.visions[Math.floor(Math.random() * this.visions.length)]
        this.vision = machineChoice
        this.interval = 500 + Math.floor( Math.random() * 1000 )

        this.reflection = !this.reflection
        
        setTimeout(() => {
          this.theMachineVision()
        }, this.interval)
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  margin: 0;
  padding: 0;
}

.goodbye{
  display: block;
  position: relative;
  width: 100vw;
  height: 100%;
}

.goodbye .window{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  text-align: center;
}

.goodbye .window img{
  max-height: 75vh;
  max-width: 100vw;
}

.prompt{
  width: 100vw;
  height: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'IM Fell English SC', serif;
  font-size: 3rem;
  color: #fff;
  text-decoration: line-through;
  text-align: center;
}

.prompt a{
  font-family: 'IM Fell English SC', serif;
  font-size: 3rem;
  color: #fff;
  text-decoration: line-through;
  text-align: center;
  margin-top: 1.5rem;
}

.prompt input{
  background: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 1.5rem;
  font-family: 'IM Fell English SC', serif;
  text-align: center;
}

.prompt input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid #fff;
}

/* .phase-2 a{
  font-family: 'IM Fell English SC', serif;
  font-size: 3rem;
  color: #fff;
  text-decoration: line-through;
  text-align: center;
  margin-top: 1.5rem;
} */

.reflection .portal{
  filter: invert(100%);
}

.flashing .view-us{
  animation: flash 0.5s ease-in-out;
  /* ALL THINGS MUST BE SO IN ORDER FOR REVOCATION */
  animation-iteration-count: 1;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
